import alaskaAirCargoCard from '../img/card_images/alaska_air_cargo_card.png';
import microsoftAzureCard from '../img/card_images/microsoft_azure_card.png';
import reversiCard from '../img/card_images/reversi_card.png';
import loneSharkGamesCard from '../img/card_images/lsg_home_page_card.png';
import xboxNewsletterCard from '../img/card_images/xbox_newsletter_card.png';
import tmobileEmailCard from '../img/card_images/tmobile_scrolling_map_card.png';
import metroEmailCard from '../img/card_images/metro_vertical_carousel_card.png';

import alaskaAirCargo from '../img/full_images/alaska_air_cargo_full.jpg';
import microsoftAzure from '../img/full_images/microsoft_azure_full.jpg';
import reversi from '../img/full_images/reversi_full.png';
import loneSharkGames from '../img/full_images/lsg_home_page_full.jpg';
import xboxNewsletter from '../img/full_images/xbox_newsletter_full.jpg';
import tmobileEmail from '../img/full_images/tmobile_scrolling_map_full.png';
import metroEmail from '../img/full_images/metro_vertical_carousel_full.png';

export const PORTFOLIO_DATA = [
  {
    id: 0,
    name: 'Alaska Air Cargo',
    urlName: 'alaska_air_cargo',
    title: 'Alaska Air Cargo website',
    stack: 'JavaScript, CSS, HTML, and Kentico CMS',
    thumb: alaskaAirCargoCard,
    image: alaskaAirCargo,
    link: 'https://www.alaskacargo.com/',
    linkText: 'Alaska Air Cargo website',
    description: [
      {
        list: false,
        text: 'Alaska Airlines wanted to implement a new design of their Alaska Air Cargo website and make the website content more easily editable by people without a technical background. Since the website uses a CMS called Kentico, this was certainly doable! As the lone front end developer, it was my task to learn the CMS quickly and work with the back end developer team to build changes in the CMS that would accommodate these two priorities.',
      },
      {
        list: false,
        text: 'I accomplished all of this with the following:',
      },
      {
        list: true,
        text: [
          'Created fully-responsive web components and content areas using JavaScript, CSS, and Kentico widgets',
          'Worked with back end developers to update and enhance Kentico widget functionality and capability',
          'Built easy-to-use templates that would allow for easier page and content creation',
          'Did a sitewide scrub for any accessibility and responsiveness issues and made fixes when necessary',
        ],
      },
    ],
  },
  {
    id: 1,
    name: 'Microsoft Azure',
    urlName: 'microsoft_azure',
    title: 'Microsoft Azure pricing pages',
    stack: 'CSHTML, JavaScript, and Sass',
    thumb: microsoftAzureCard,
    image: microsoftAzure,
    link: 'https://azure.microsoft.com/en-us/pricing/details/digital-twins/',
    linkText: 'Azure Digital Twins page',
    description: [
      {
        list: false,
        text: 'Microsoft wanted to update the design and UI of the Azure pricing pages to modernize the look and bring it in line with similar updates in the rest of the Azure website. As the main front end developer on this project, it was my task to implement these new designs and work with other team developers to build these changes in a way that was consistent with the other sitewide updates.',
      },
      {
        list: false,
        text: 'I accomplished the following:',
      },
      {
        list: true,
        text: [
          'Created a new pricing page template with CSHTML, JavaScript, and Sass by leveraging new templates created for other Azure pages',
          'Worked with team designers to implement the new look, including a new subnav and an all-new hero design',
          'Applied the template as well as content and styling updates to over 200 pricing pages',
          'Modified interactive elements like sticky headers to work with the new template and the new sitewide header',
        ],
      },
    ],
  },
  {
    id: 2,
    name: 'Reversi',
    urlName: 'reversi',
    title: 'Reversi web-based board game app',
    stack: 'React, JavaScript, CSS, Sass, and HTML',
    thumb: reversiCard,
    image: reversi,
    link: 'https://briancoppolagames.com/reversi',
    linkText: 'Play Reversi: The Reversi-verse',
    description: [
      {
        list: false,
        text: 'Reversi: The Reversi-verse is my pass-and-play version of the classic tabletop game Reversi (or Othello), which was originally invented in late 19th-century England. This was a passion project of mine, and represented a fun intersection between two of my greatest loves (tabletop games and front end web development).',
      },
      {
        list: false,
        text: 'Technologies used:',
      },
      {
        list: true,
        text: [
          'React to create a dynamic game state and build reusable UI components throughout the app',
          'Multiple Sass files to organize the CSS styling and utilize variables and mixins',
          'Firebase as a platform for the finished app',
          'Google fonts and Icomoon icons',
        ],
      },
      {
        list: false,
        text: 'My main areas of focus were:',
      },
      {
        list: true,
        text: [
          'An easy game experience that makes important game states like player turns and an ongoing score tally abundantly clear',
          "UI elements that make turn options and results easier to understand (like using small dots to show all of a player's legal moves on their turn)",
          'Ability for players to customize their game experience with a choice of player name and disc color',
          'A simple rules page that clearly illustrates how to play so players can learn on their own and get answers to rules questions',
        ],
      },
    ],
  },
  {
    id: 3,
    name: 'Lone Shark Games',
    urlName: 'lone_shark_games',
    title: 'Lone Shark Games website',
    stack: 'Design and WordPress/CSS development',
    thumb: loneSharkGamesCard,
    image: loneSharkGames,
    description: [
      {
        list: false,
        text: 'I was tasked with the entire redesign and redevelopment of the Lone Shark Games website from the ground up. I used the following:',
      },
      {
        list: true,
        text: [
          'WordPress using a theme called Total',
          'A significant chunk of custom CSS to control positioning, colors, gradients, CSS animations, and custom page elements (like the water and fin logo in the footer)',
          'Some adjustments to the Total theme using PHP to render the header and footer and display some dynamic information',
          'Photoshop and Illustrator to create and tweak design and branding elements',
        ],
      },
      {
        list: false,
        text: 'My main areas of focus were:',
      },
      {
        list: true,
        text: [
          'An easy user experience that allows the user to navigate to information quickly and reflects the latest website conventions',
          "A fun, responsive design that captures the playfulness of Lone Shark's branding and culture",
          'A blog page that allows a high-profile game designer to provide game updates to fans as well as provide a platform for thoughts and general messages',
        ],
      },
    ],
  },
  {
    id: 4,
    name: 'Xbox newsletter',
    urlName: 'xbox_newsletter',
    title: 'Xbox monthly gamer newsletter',
    stack: 'HTML, CSS animation, and AMPscript',
    thumb: xboxNewsletterCard,
    image: xboxNewsletter,
    link: '/portfolio/emails/xbox_newsletter/xbox_newsletter.html',
    linkText: 'View in browser',
    description: [
      {
        list: false,
        text: 'Xbox wanted to create a monthly newsletter for gamers to keep them posted on the latest Xbox news, products, and events, and it was our challenge to make that experience informative as well as fun and engaging. Since this was also a recurring email, it was important to create a flexible template that can make subsequent newsletters easy to update and quick to develop.',
      },
      {
        list: false,
        text: "As a result, we used SalesForce as an email deployment tool. SalesForce allowed us to build a modular template, and we could use AMPscript in the HTML to populate the email with dynamic content specific to the recipient's stats, location, and preferences.",
      },
      {
        list: false,
        text: 'I prioritized the following:',
      },
      {
        list: true,
        text: [
          'Building a mobile-first email, as a clear majority of gamers experience this email on mobile devices',
          'Gamer stats at the top of the email to personalize the experience with stats gamers want to see',
          'A modular body that allows for easy creation, management, and dynamic manipulation of content blocks',
          'Providing an option for an image carousel if a content block requires more than one image',
          'Giving a clear Xbox feel and personality to the design and experience',
        ],
      },
    ],
  },
  {
    id: 5,
    name: 'T-Mobile email',
    urlName: 'tmobile_email',
    title: 'T-Mobile scrolling map email',
    stack: 'HTML and CSS animation',
    thumb: tmobileEmailCard,
    image: tmobileEmail,
    link: '/portfolio/emails/tmobile_scrolling_map/tmobile_scrolling_map_email.html',
    linkText: 'View in browser',
    description: [
      {
        list: false,
        text: 'As part of an initiative to explore the possibilities of creating more dynamic email experiences with T‑Mobile, I pioneered an effort to research the technical aspects of adding interactivity and CSS animation to T‑Mobile emails. The scrolling map is one T‑Mobile was particularly excited to see, as it fit the worldly scope of the adventure.',
      },
      {
        list: false,
        text: 'I prioritized the following:',
      },
      {
        list: true,
        text: [
          'A CSS scrolling map that will be responsive and work in as many devices/platforms as possible',
          'Scrolling that will temporarily stop on hover to make it easier for the user to click a map link',
          "A static fallback experience in clients like Outlook that can't render the scrolling but can still render the map and links",
          'Addressing device and platform rendering quirks',
        ],
      },
    ],
  },
  {
    id: 6,
    name: 'Metro email',
    urlName: 'metro_email',
    title: 'Metro scrolling carousel email',
    stack: 'HTML and CSS animation',
    thumb: metroEmailCard,
    image: metroEmail,
    link: '/portfolio/emails/metro_vertical_carousel/metro_vertical_carousel.html',
    linkText: 'View in browser',
    description: [
      {
        list: false,
        text: 'Metro wanted to do something slightly different with this one and had ideas for a three-panel carousel that would scroll vertically instead of horizontally (which was our convention up to this point). This required some research to get the scrolling to render properly, but it also represented an opportunity to utilize some newer CSS properties and capabilities.',
      },
      {
        list: false,
        text: 'I prioritized the following:',
      },
      {
        list: true,
        text: [
          'Utilized CSS keyframes, variables, and the calc function to generate the carousel',
          'Embedded a Metro-approved font with @font-face to enhance branding and flavor',
          'Set the carousel to auto-play and loop 3 times, then stop, which made for a better user experience than an infinite looping. As a result, the most important messaging was given to the last slide.',
          "Created a static fallback experience in clients like Outlook that can't render the scrolling. Fallback image is the last slide.",
        ],
      },
    ],
  },
];
